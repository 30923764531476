const data = {
  getCustomers: [
    {
      id: "cus_KvKPMNjoQy3q4Y",
      object: "customer",
      address: null,
      balance: 0,
      created: 1641605389,
      currency: null,
      default_source: null,
      delinquent: false,
      description: "John Doe account 2",
      discount: null,
      email: "email@domain.com",
      invoice_prefix: "DB292EA1",
      invoice_settings: {
        custom_fields: null,
        default_payment_method: null,
        footer: null,
      },
      livemode: false,
      metadata: {},
      name: "John Doe 2",
      phone: null,
      preferred_locales: [],
      shipping: null,
      tax_exempt: "none",
    },
    {
      id: "cus_KuYXl8749p39DY",
      object: "customer",
      address: null,
      balance: 0,
      created: 1641427315,
      currency: "gbp",
      default_source: null,
      delinquent: false,
      description: "John Doe account 3",
      discount: null,
      email: "email@domain.com",
      invoice_prefix: "B85345DC",
      invoice_settings: {
        custom_fields: null,
        default_payment_method: null,
        footer: null,
      },
      livemode: false,
      metadata: {},
      name: "John Doe 3",
      phone: null,
      preferred_locales: [],
      shipping: null,
      tax_exempt: "none",
    },
    {
      id: "cus_KuCgR9HGZkp72z",
      object: "customer",
      address: null,
      balance: 0,
      created: 1641345994,
      currency: "gbp",
      default_source: "src_1KEOJK2S4nFyMvKGG1kChbnw",
      delinquent: false,
      description: "John Doe account",
      discount: null,
      email: "email@domain.com",
      invoice_prefix: "4BC988A9",
      invoice_settings: {
        custom_fields: null,
        default_payment_method: null,
        footer: null,
      },
      livemode: false,
      metadata: {},
      name: "John Doe",
      phone: null,
      preferred_locales: [],
      shipping: null,
      tax_exempt: "none",
    },
  ],
};

export default data;

import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import createCustomer from "../lib/createCustomer";

const CreateCustomer = ({ setView, setError, setShowOverlay }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [customer, setCustomer] = useState("");

  useEffect(() => {
    customer && setView("invoices");
  }, [customer, setView]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name, email, description };
    createCustomer(setShowOverlay, setCustomer, setError, data);
  };

  return (
    <div className="orenda-frontend">
      <h3 className="text-center my-5">Create Customer For Invoicing</h3>
      <Row className="d-flex justify-content-center">
        <Col lg={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <div className="d-flex flex-row justify-content-between">
              <Button
                variant="secondary"
                type="button"
                onClick={() => setView("invoices")}
              >
                Back
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateCustomer;

import handleOnboardingData from "../mock_data/handleOnboarding.data";

export default function handleOnboarding(setShowOverlay, setData, setError) {
  console.log("===================================================");
  console.log("Handle onboarding started");
  setShowOverlay(true);
  if (!window.OrendaGetResponse || window.isSiteBuilder) {
    console.log("===================================================");
    console.log("Inside Mocks condition");
    setTimeout(() => {
      setData(handleOnboardingData.onboarding);
      setShowOverlay(false);
    }, 1000);
    return;
  }

  console.log("===================================================");
  console.log("Mocks condition skipped");
  return window.OrendaGetResponse(
    {
      event_name: "handleOnboarding",
      data: JSON.stringify({
        refresh_url: window.location.href,
        return_url: window.location.href,
      }),
    },
    (response) => {
      console.log("===================================================");
      console.log("inside event code");
      if (response.error) {
        setShowOverlay(false);
        return setError(true);
      }
      if (!response.stripe?.onboarding) return setShowOverlay(false);
      setData(response.stripe.onboarding);
      return setShowOverlay(false);
    }
  );
}

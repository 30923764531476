import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import cancelInvoice from "../lib/cancelInvoice";
import { toast } from "react-toastify";

const ViewInvoice = ({
  setShowOverlay,
  setInvoiceView,
  setError,
  data,
  setResponse,
  renderStatus,
}) => {
  const isOpen = data.status === "open";

  const handleCancel = () => {
    const reqData = { id: data.id };
    cancelInvoice(setShowOverlay, setResponse, setError, reqData);
  };

  return (
    <div className="orenda-frontend">
      <Row className="m-3">
        <Col lg={12}>
          <h3 className="text-center">Invoice Details</h3>
          <div className="mb-3 d-flex justify-content-between">
            <Button
              variant="secondary"
              type="button"
              onClick={() => setInvoiceView("list-invoices")}
            >
              Back
            </Button>
            <Button
              variant="danger"
              disabled={!isOpen}
              onClick={() =>
                window.confirm(
                  "Are you sure you want to cancel this Invoice?"
                ) && handleCancel()
              }
            >
              Cancel Invoice
            </Button>
          </div>
          <div className="mt-5">
            {data.status && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Status
                </Col>
                <Col lg={9}>{renderStatus(data.status)}</Col>
              </Row>
            )}
            {data.customer_name && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Customer Name
                </Col>
                <Col lg={9}>{data.customer_name}</Col>
              </Row>
            )}
            {data.customer_email && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Customer Email
                </Col>
                <Col lg={9}>{data.customer_email}</Col>
              </Row>
            )}
            {data.hosted_invoice_url && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Payment URL
                </Col>
                <Col lg={9}>
                  <div
                    className="text-decoration-underline"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(data.hosted_invoice_url);
                      toast.success("Link copied to clipboard.");
                    }}
                  >
                    Click to copy URL to clipboard
                  </div>
                </Col>
              </Row>
            )}
            {data.invoice_pdf && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  PDF URL
                </Col>
                <Col lg={9}>
                  <div
                    className="text-decoration-underline"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(data.invoice_pdf);
                      toast.success("Link copied to clipboard.");
                    }}
                  >
                    Click to copy URL to clipboard
                  </div>
                </Col>
              </Row>
            )}
            {data?.lines?.data?.length && (
              <>
                <Row className="mt-3">
                  <Col lg={12}>
                    <h5>Invoice Items</h5>
                  </Col>
                </Row>
                {[...data.lines.data].reverse().map((m, i) => (
                  <Row key={i}>
                    <Col lg={12}>
                      {m.description && (
                        <Row>
                          <Col lg={3} className="font-weight-bold">
                            Description
                          </Col>
                          <Col lg={9}>{m.description}</Col>
                        </Row>
                      )}
                      {m.quantity && (
                        <Row>
                          <Col lg={3} className="font-weight-bold">
                            Quantity
                          </Col>
                          <Col lg={9}>{m.quantity}</Col>
                        </Row>
                      )}
                      {m.price.unit_amount && m.price.currency && (
                        <Row>
                          <Col lg={3} className="font-weight-bold">
                            Unit Amount
                          </Col>
                          <Col lg={9}>{`${m.price.unit_amount / 100} ${
                            m.price.currency
                          }`}</Col>
                        </Row>
                      )}
                      {m.amount && m.currency && (
                        <Row>
                          <Col lg={3} className="font-weight-bold">
                            Total Amount
                          </Col>
                          <Col lg={9}>{`${m.amount / 100} ${m.currency}`}</Col>
                        </Row>
                      )}
                    </Col>
                    <hr className="my-2" />
                  </Row>
                ))}
              </>
            )}

            <Row className="mt-3">
              <Col lg={12}>
                <h5>Invoice Details</h5>
              </Col>
            </Row>
            {data.total && data.currency && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Invoice Amount
                </Col>
                <Col lg={9}>{`${data.total / 100} ${data.currency}`}</Col>
              </Row>
            )}
            {data.application_fee_amount && data.currency && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Transaction Charges
                </Col>
                <Col lg={9}>
                  {`${data.application_fee_amount / 100} ${data.currency}`}
                </Col>
              </Row>
            )}
            {data.created && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Created On
                </Col>
                <Col lg={9}>
                  {new Date(data.created * 1000).toLocaleDateString("en-UK")}
                </Col>
              </Row>
            )}
            {data.due_date && (
              <Row>
                <Col lg={3} className="font-weight-bold">
                  Due Date
                </Col>
                <Col lg={9}>
                  {new Date(data.due_date * 1000).toLocaleDateString("en-UK")}
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ViewInvoice;

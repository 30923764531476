import getAllInvoicesData from "../mock_data/getAllInvoices.data";

export default function handleOnboarding(setShowOverlay, setData, setError) {
  setShowOverlay(true);
  if (!window.OrendaGetResponse || window.isSiteBuilder) {
    setTimeout(() => {
      setData(getAllInvoicesData.getInvoices);
      setShowOverlay(false);
    }, 1000);
    return;
  }

  const getTransactions = async (url) => {
    return (
      (await window.fetch(url).then((response) => response.json()))
        ?.transactions || {}
    );
  };

  return window.OrendaGetResponse(
    {
      event_name: "getAllInvoices",
    },
    async (response) => {
      if (response.error) {
        setShowOverlay(false);
        return setError(true);
      }
      if (!response.stripe?.url) return setShowOverlay(false);
      const responseData = await getTransactions(response.stripe.url);
      setData(responseData);
      return setShowOverlay(false);
    }
  );
}

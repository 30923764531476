import createInvoiceData from "../mock_data/createInvoice.data";

export default function createInvoice(setShowOverlay, setData, setError, data) {
  setShowOverlay(true);
  if (!window.OrendaGetResponse || window.isSiteBuilder) {
    setTimeout(() => {
      setData(createInvoiceData.createInvoice);
      setShowOverlay(false);
    }, 1000);
    return;
  }

  return window.OrendaGetResponse(
    {
      event_name: "createInvoice",
      data: JSON.stringify(data),
    },
    (response) => {
      if (response.error) {
        setShowOverlay(false);
        return setError(true);
      }
      if (!response.stripe?.createInvoice) return setShowOverlay(false);
      setData(response.stripe.createInvoice);
      return setShowOverlay(false);
    }
  );
}

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ClipLoader from "react-spinners/BounceLoader";
import { toast, ToastContainer } from "react-toastify";

import handleOnboarding from "./lib/handleOnboarding";
import CreateInvoice from "./components/CreateInvoice";
import CreateCustomer from "./components/CreateCustomer";
import Invoices from "./components/Invoices";

import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const [details, setDetails] = useState(null);
  const [showOverlay, setShowOverlay] = useState(true);
  const [error, setError] = useState(false);
  const [view, setView] = useState("onboarding");

  useEffect(() => {
    setTimeout(() => {
      handleOnboarding(setShowOverlay, setDetails, setError);
    }, 5000);
  }, []);

  useEffect(() => {
    if (details?.onBoarded && view === "onboarding") setView("invoices");
  }, [details, view]);

  useEffect(() => {
    if (error) {
      toast.error("Unfortunately an error occurred.");
      setError(false);
    }
  }, [error]);

  const renderComponent = () => {
    switch (view) {
      case "onboarding":
        return (
          <>
            In order to start using invoices, you are required to complete
            onboarding process
            {!details?.onBoarded ? (
              <Button onClick={() => window.open(details?.url, "_self")}>
                {details?.status === "pending"
                  ? "Start Onboarding"
                  : "Complete Onboarding"}
              </Button>
            ) : null}
          </>
        );
      case "invoices":
        return (
          <Invoices
            {...{
              setView,
              setError,
              setShowOverlay,
              stripe_dashboard_url: details?.stripe_dashboard_url,
            }}
          />
        );
      case "create_invoice":
        return <CreateInvoice {...{ setView, setError, setShowOverlay }} />;
      case "create_customer":
        return <CreateCustomer {...{ setView, setError, setShowOverlay }} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="orenda-frontend">
      {showOverlay && (
        <div className="loader bounce">
          <p>Please wait...</p>
          <ClipLoader size={30} />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={showOverlay ? "d-none" : null}>{renderComponent()}</div>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";

function checkCore(callback) {
  var interval = setInterval(function () {
    if (window.OrendaStage) {
      clearInterval(interval);
      callback();
    }
  }, 200);
}

checkCore(function () {
  if (document.getElementById("StripeInvoicingWidget")) {
    const WidgetProps = document.querySelector("#StripeInvoicingWidget");
    ReactDOM.render(
      <React.StrictMode>
        <App widget={WidgetProps} />
      </React.StrictMode>,
      document.getElementById("StripeInvoicingWidget")
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import cancelInvoiceData from "../mock_data/cancelInvoice.data";

export default function cancelInvoice(setShowOverlay, setData, setError, data) {
  setShowOverlay(true);
  if (!window.OrendaGetResponse || window.isSiteBuilder) {
    setTimeout(() => {
      setData(cancelInvoiceData.cancelInvoice);
      setShowOverlay(false);
    }, 1000);
    return;
  }

  return window.OrendaGetResponse(
    {
      event_name: "cancelInvoice",
      data: JSON.stringify(data),
    },
    (response) => {
      if (response.error) {
        setShowOverlay(false);
        return setError(true);
      }
      if (!response.stripe?.cancelInvoice) return setShowOverlay(false);
      setData(response.stripe.cancelInvoice);
      return setShowOverlay(false);
    }
  );
}

import React, { useState, useEffect } from "react";
import { Button, Row, Col, Table } from "react-bootstrap";
import getAllInvoices from "../lib/getAllInvoices";
import ViewInvoice from "./ViewInvoice";

const Invoices = ({
  setView,
  setError,
  setShowOverlay,
  stripe_dashboard_url,
}) => {
  const [invoices, setInvoices] = useState([]);
  const [invoiceView, setInvoiceView] = useState("list-invoices");
  const [data, setData] = useState({});
  const [response, setResponse] = useState(null);

  useEffect(() => {
    getAllInvoices(setShowOverlay, setInvoices, setError);
  }, []);

  useEffect(() => {
    if (response) {
      getAllInvoices(setShowOverlay, setInvoices, setError);
      setInvoiceView("list-invoice");
      setResponse(null);
    }
  }, [response]);

  const handleRowClick = (invoiceData) => {
    setData(invoiceData);
    setInvoiceView("view-invoice");
  };

  const renderStatus = (status) => {
    if (status === "void") return "cancelled";
    return status;
  };

  if (invoiceView === "view-invoice")
    return (
      <ViewInvoice
        {...{
          setShowOverlay,
          setInvoiceView,
          setError,
          data,
          setResponse,
          renderStatus,
        }}
      />
    );

  return (
    <div className="orenda-frontend">
      <Row className="m-3">
        <Col lg={12}>
          <h3 className="text-center">Invoices</h3>
          <div className="mb-3 d-flex justify-content-between">
            <div>
              {stripe_dashboard_url && (
                <Button
                  className="mx-2"
                  onClick={() => window.open(stripe_dashboard_url, "_blank")}
                >
                  Stripe Dashboard
                </Button>
              )}
            </div>

            <div>
              <Button
                className="mx-2"
                onClick={() => setView("create_customer")}
              >
                Create Customer
              </Button>
              <Button onClick={() => setView("create_invoice")}>
                Create Invoice
              </Button>
            </div>
          </div>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th style={{ minWidth: 170 }}>Payee Name</th>
                <th style={{ minWidth: 250 }}>Payee Email</th>
                <th style={{ minWidth: 110 }}>Amount</th>
                <th style={{ minWidth: 120 }}>Date</th>
                <th style={{ minWidth: 100 }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((m, i) => (
                <tr
                  key={i}
                  className="cursor-pointer"
                  onClick={() => handleRowClick(m)}
                >
                  <td>{m.customer_name || ""}</td>
                  <td>{m.customer_email || ""}</td>
                  <td>
                    {m.total && m.currency
                      ? `${m.total / 100} ${m.currency}`
                      : ""}
                  </td>
                  <td>
                    {new Date(m.created * 1000).toLocaleDateString("en-UK")}
                  </td>
                  <td>{renderStatus(m.status) || ""}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default Invoices;

import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import getAllCustomers from "../lib/getAllCustomers";
import createInvoice from "../lib/createInvoice";

const CreateInvoice = ({ setView, setError, setShowOverlay }) => {
  const invoiceItem = {
    description: "",
    quantity: "",
    amount: "",
  };
  const [invoiceItems, setInvoiceItems] = useState([invoiceItem]);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [invoiceRef, setInvoiceRef] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [invoiceMessage, setInvoiceMessage] = useState("");
  const [invoice, setInvoice] = useState(null);
  const [currency, setCurrency] = useState("GBP");

  const amount =
    invoiceItems.reduce((a, b) => {
      return +a + +b.amount * +b.quantity;
    }, 0) || 0;

  useEffect(() => {
    getAllCustomers(setShowOverlay, setCustomers, setError);
  }, []);

  useEffect(() => {
    invoice && setView("invoices");
  }, [invoice, setView]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const dueDateDays = parseInt(
      (new Date(dueDate) - new Date()) / (1000 * 60 * 60 * 24),
      10
    );
    const data = {
      customer,
      invoiceRef,
      dueDate: dueDateDays,
      currency,
      invoiceItems,
      amount,
      invoiceMessage,
    };
    createInvoice(setShowOverlay, setInvoice, setError, data);
  };

  const handleAddInvoiceItem = () => {
    setInvoiceItems([...invoiceItems, { ...invoiceItem }]);
  };

  const handleRemoveInvoiceItem = () => {
    setInvoiceItems(invoiceItems.slice(0, -1));
  };

  const handleInvoiceItemChange = (i, name, value) => {
    const items = invoiceItems.map((m, idx) =>
      i !== idx ? m : { ...m, [name]: value }
    );
    setInvoiceItems(items);
  };

  return (
    <div className="orenda-frontend">
      <h3 className="text-center mb-3 mt-4">Create Invoice</h3>
      <Row className="d-flex justify-content-center mb-5">
        <Col lg={9}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="setCurrency" className="mb-3">
                  <Form.Label>Choose a customer</Form.Label>
                  <Select
                    options={customers.map((c) => ({
                      value: c.id,
                      label: `${c.name} | ${c.email}`,
                    }))}
                    isClearable={true}
                    onChange={(x) => setCustomer(x ? x.value : null)}
                    className="noborder"
                    classNamePrefix="or-select"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="create_button" className="mb-3">
                  <Form.Label className="opacity-0">.</Form.Label>
                  <Button
                    onClick={() => setView("create_customer")}
                    className="d-block"
                  >
                    Create Customer
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <h5>Invoice Details</h5>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Invoice Ref</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={invoiceRef}
                    onChange={(e) => setInvoiceRef(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Due Date</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    placeholder=""
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    min={new Date().toLocaleDateString("en-ca")}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder=""
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <h5>Invoice Details</h5>
              </Col>
            </Row>
            {invoiceItems.map((m, idx) => (
              <Row key={idx}>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={m.description}
                      onChange={(e) =>
                        handleInvoiceItemChange(
                          idx,
                          "description",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder=""
                      value={m.quantity}
                      onChange={(e) =>
                        handleInvoiceItemChange(idx, "quantity", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Unit Amount</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder=""
                      value={m.amount}
                      onChange={(e) =>
                        handleInvoiceItemChange(idx, "amount", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            ))}

            <Row className="mb-4">
              <Col lg={6}>
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleAddInvoiceItem}
                >
                  +
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  className="mx-2"
                  disabled={invoiceItems.length === 1}
                  onClick={handleRemoveInvoiceItem}
                >
                  -
                </Button>
              </Col>
              <Col
                lg={3}
                className="d-flex justify-content-end align-items-center"
              >
                Total
              </Col>
              <Col lg={3}>
                <Form.Control
                  readOnly
                  type="number"
                  placeholder=""
                  value={amount}
                />
              </Col>
            </Row>

            <Row>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Add a message</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={invoiceMessage}
                  onChange={(e) => setInvoiceMessage(e.target.value)}
                />
              </Form.Group>
              <div className="d-flex flex-row justify-content-between">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => setView("invoices")}
                >
                  Back
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateInvoice;
